@import "scss/variables.scss";
@import "scss/theme/variables.scss";

.VehicleCardNotes-input {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 $spacing-md;
  border: 2px solid $recon-grey;
  border-radius: 4px;

  label {
    opacity: 0.6;
    transition: 0.5s opacity;
    transform: rotate(90deg);

    &:hover {
      opacity: 1;
    }
  }

  .VehicleCardNotes-loading {
    flex: unset;
    padding-top: $spacing-sm;
  }

  div {
    flex: 1;
    margin: 0;
    z-index: 1;

    .DraftEditor-editorContainer {
      padding: 0;
      max-height: 40px;
      overflow: auto;
    }

    .mentionable-input-editor {
      padding: $spacing-md $spacing-sm;
    }
  }

  > button {
    padding: 0;
    margin: 0;

    opacity: 0.6;
    transition: 0.5s opacity;

    &:hover {
      opacity: 1;
    }
  }
}
