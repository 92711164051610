@import "../../../../../scss/theme/variables.scss";

.EngagementsHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 22px;
    justify-content: space-between;
    padding-left: 24px;
    padding-top: 22px;
    padding-bottom: 24px;
    text-transform: capitalize;
  
    &-count {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.15px;
      color: $primary;
      padding-right: 24px;
    }
  
    &-filters {
      display: flex;
      align-items: center;
      width: 100%;
    }
  
    &-toolbar {
      display: grid;
      width: 100%;
      align-items: center;
      // border: 1px solid #f00;
      gap: 5px;
      grid-template-columns: 200px auto max-content;
      > div {
        gap: 1px;
        // border: 1px solid #f00;
      }
      .filters {
        display: flex;
        flex-grow: 1;
        align-items: center;
      }
    }
  
    &-pagination {
      align-self: flex-end;
      align-items: center;
      display: flex;
    }
  
    &-clear-button {
      border: 1px solid $primary !important;
  
      &:hover {
        border: 1px solid $primary !important;
      }
    }
  }