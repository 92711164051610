@import 'scss/variables';
@import 'scss/theme/variables';

.VehicleCardShowroomDesktop {
  display: flex;

  .VehicleCardShowroomDesktop-vehicleInfo {
    display: flex;

    .vehicle-image-count {
      right: unset;
      left: 25px;
      bottom: 20px;
    }

    .VehicleCard-year-make-model {
      margin-left: $spacing-md;
    }
  }

  .VehicleCard-details-table {
    margin-left: $spacing-sm;
  }

  .VehicleCardShowroomDesktop-vehicleExtras {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-left: auto;

    .showroom-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;

      .showroom-badge {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        opacity: 0.6;
        transition: 0.5s opacity;
        padding: 0;
        text-transform: uppercase;

        .engage-btn-icon {
          height: 24px;
        }

        &:hover {
          opacity: 1;
        }
      }

      div+div {
        margin-left: $spacing-lg;
      }
    }
  }
}
