@import "../../../scss/variables.scss";

.Checkbox {
  display: block;
  position: relative;
  margin: auto;
  margin-left: 0;
  margin-right: 20px;
  padding: 0;
  cursor: pointer;
  clear: both;

  .Checkbox-custom-checkbox {
    position: absolute;
    height: 18px;
    width: 18px;
    background-color: transparent;
    border: 2px solid #dadada;
    border-radius: 2px;
  }

  .Checkbox-custom-checkbox::after {
    position: absolute;
    content: "";
    left: 12px;
    top: 12px;
    height: 0;
    width: 0;
    border: solid #dadada;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity: 1;
  }
}

.Checkbox input:checked ~ .Checkbox-custom-checkbox {
  background-color: #263238;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid #263238;
}

.Checkbox input:checked ~ .Checkbox-custom-checkbox::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 5px;
  top: 0;
  width: 5px;
  height: 11.67px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}

.Checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
