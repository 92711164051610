@import "../../../../scss/variables.scss";

.VehicleLog {
  &-section-header {
    vertical-align: middle;
    padding: $spacing-slg;
    padding-left: 0;
    padding-right: 0;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: $recon-alt-text;

    &:first-child {
      padding-top: 0 !important;
    }
  }

  .HistoryItem-container {
    margin-left: 0;
  }
}
