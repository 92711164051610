@import 'scss/variables';
@import 'scss/theme/variables';

.VehicleCardShowroomMobile {
  display: flex;
  flex-direction: column;

  .VehicleCardShowroomMobile-vehicleInfo {
    display: flex;

    .VehicleImageView-showroom-size {
      height: 69px;
      width: 92px;

      svg {
        width: 69px;
      }
    }

    .vehicle-image-count {
      right: unset;
      left: 20px;
      top: 50px;
      bottom: unset;
    }

    .VehicleCard-year-make-model {
      margin-left: $spacing-md;
    }
  }

  .showroom-priceChip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: $spacing-md;
  }

  .showroom-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;

    .showroom-badge {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      opacity: 0.6;
      transition: 0.5s opacity;
      padding: 0;
      text-transform: uppercase;

      .engage-btn-icon {
        height: 24px;
      }

      &:hover {
        opacity: 1;
      }
    }

    div+div {
      margin-left: $spacing-lg;
    }
  }
}
