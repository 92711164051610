@import "../../../scss/theme/variables.scss";

.UploadPlaceholder {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;

  &-image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &-overlay {
    position: absolute;
    left: 0;
    top: 0;
    background: $muted;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-progress {
      width: 40px;
      max-width: 40px;
      height: 40px;
      max-height: 40px;

      &-large {
        width: 60px;
        max-width: 60px;
        height: 60px;
        max-height: 60px;
      }
    }
  }

  &-cancel-button {
    position: absolute;
    right: -5px;
    top: -5px;
    background: $primary;
    width: 18px;
    max-width: 18px;
    height: 18px;
    max-height: 18px;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;

    &-icon {
      fill: $light !important;
      font-size: 14px !important;
    }
  }

  .CircularProgressbar {
    &-path {
      stroke: $light !important;
      stroke-linecap: butt;
    }

    &-trail {
      stroke: rgba(255, 255, 255, 0.5);
    }
  }
}

.IconContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-inner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $light;
    width: 55%;
    min-width: 55%;
    height: 80%;
    min-height: 80%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 3px;

    svg {
      width: 56px;
      height: 43px;
    }
  }

  &-video {
    background-color: #19a55c;
  }

  &-attachment {
    background-color: $danger;
  }

  &-image {
    background-color: rgb(104, 109, 224);
  }
}

.UploadPlaceholder-progress-icon.MuiSvgIcon-root {
  fill: #dadada !important;
  font-size: 33px !important;

  &:hover {
    fill: #dadada !important;
  }
}
