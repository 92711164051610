@import "../../../../scss/variables.scss";
@import "../../../../scss/theme/variables.scss";
@import "../../../../scss/mixins.scss";

.ReconProgressSection {
  padding: 0 !important;
  padding-bottom: 40px !important;
  padding-right: 16px !important;

  .CircularProgressChart-text {
    padding-right: 16px !important;
  }

  @include lg {
    padding-right: 20px !important;
  }

  &-charts {
    box-shadow: inset 0 -1px 0 #e6e7e8;
    padding-bottom: $spacing-md;
  }

  &-progress {
    padding-top: 20px !important;
    padding-bottom: $spacing-slg !important;

    &-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.3px;
      color: $recon-background-secondary;
    }
  }

  &-row-container {
    padding-bottom: $spacing-slg;
  }

  &-info-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: $recon-alt-text;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;

    .stepName,
    .assigneeName {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: $primary;
      text-transform: capitalize;
    }
  }

  &-info-value {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: $recon-background-secondary;
  }

  &-time-value {
    padding-right: 24px !important;
  }

  .Dropdown .Dropdown-btn.dropdown .dropdown-toggle.btn:disabled {
    background-color: transparent !important;
  }

  .UsersDropdown.Dropdown-btn.dropdown {
    max-width: none;
  }

  .step-button-base {
    height: 48px;
  }
}
