@import '../../../scss/variables';
@import '../../../scss/theme/variables.scss';

.vdp-page-container {
  width: 100% !important;
  height: 100% !important;
  background-color: $recon-background-tertiary;
}

.VehicleDetails2 {
  min-height: 180px;
  background: #e6e6e6;
  display: flex;
  flex-direction: column;

  .VehicleImageView-size-sm {
    height: 75px;
  }

  .VehicleDetails-Info {
    margin-bottom: 1px;
    position: relative;
  }

  .VehicleDetails-Page {
    border-top: 1px solid rgba(38, 50, 56, 0.25);
    position: absolute;
    top: 112px;
    bottom: 0;
    left: 0;
    right: 0;

    .VehicleDetails-Container {
      display: flex !important;
      flex-direction: row;
      height: 100% !important;
      width: 100% !important;

      .VehicleDetails-Menu {
        background: #fff;
        box-shadow: inset -1px 0 0 rgba(38, 50, 56, 0.25);
      }

      .VehicleDetails-Menu.open {
        min-width: 260px;
      }

      @keyframes collapseAnimation {
        from {
          min-width: 260px;
        }
        to {
          min-width: 61px;
        }
      }

      .VehicleDetails-Menu.collapsed {
        min-width: 61px;
        animation: collapseAnimation 125ms ease-in-out;
      }

      .VehicleDetails-Section {
        background: #e6e6e6;
        flex-grow: 1;
        height: 100% !important;
      }
    }
  }

  @media screen and (max-width: 1291px) {
    .VehicleDetails-Page {
      top: 166px;
    }
  }

  @media screen and (max-width: 902px) {
    .VehicleDetails-Page {
      top: 200px;
    }

    .vdp-tags-list {
      margin-top: -64px !important;
    }
  }

  @media screen and (max-width: 612px) {
    .VehicleDetails-Page {
      top: 260px;
    }
  }

  .vdp-outer-container {
    padding-top: $spacing-sm;
    padding-bottom: 4px;
    box-shadow: 0 2px 1px -1px $recon-black-shadow;
  }

  .vdp-container {
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 1199px) {
      padding: 0 12px;
    }

    > .MuiGrid-spacing-xs-3 {
      margin: -4px 0 0;
    }

    .vdp-header-col {
      flex: 1 1 0;
      margin: 0 5px;

      @media screen and (min-width: 1200px) {
        margin: 0 18px;
      }
    }
  }

  .VehicleDetails-title {
    margin-left: 0 !important;
  }

  .vdp-header-col-complete {
    flex: 1 1 auto;
    width: 24%;
    margin: 0 18px;
    padding-top: 12px;
    align-self: flex-start;
  }

  .back-button {
    position: absolute;
    top: $spacing-sm;
    left: 0;
  }

  .inner-container {
    background-color: $recon-white;
  }

  .step-button {
    width: 40%;
    line-height: 2.75;
  }

  .align-items-center {
    align-items: center;
  }

  .vdp-col-1 {
    height: 100% !important;
    width: 200px;
  }

  .bc {
    background-color: #c00 !important;
  }

  .vdp-col-2 {
    overflow: hidden !important;
    width: 200px;
  }

  .VehicleDetails-recon-complete {
    font-size: x-large;
  }

  .vdp-tags-list {
    margin-top: $spacing-md;
    margin-left: -313px;
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (min-width: $screen-md-min) {
    .vdp-tags-list {
      margin-left: 0;
    }
  }
}

.VehicleDetails-step-title {
  margin-top: 10px !important;
}

.VehicleDetails-time-title {
  display: inline-block !important;
  margin: 4px $spacing-md 0 0 !important;
}

.VehicleDetails-Mobile {
  .VehicleDetails-Mobile-recon-complete {
    font-size: x-large;
  }

  .VehicleDetails-Mobile-row {
    margin: 0 0 20px;
    width: 100%;
    padding: 0 $spacing-lg;
    text-align: center;

    .VehicleDetails-Mobile-info {
      padding: 4px;
    }

    .VehicleDetails-Mobile-info strong {
      text-transform: uppercase;
    }
  }

  .vdp-header-correction {
    width: 100%;
    padding: 0 $spacing-lg;
    margin-bottom: $spacing-lg;
  }

  .container {
    width: 100%;
    margin: 0 0 0 0;
    padding: 0 0;
  }

  .VehicleDetails-Mobile-tag-sold {
    margin: $spacing-sm 10px $spacing-xl 0;
    padding: 4px 8px;
    width: 112px;
    height: 32px;
    font-weight: $recon-medium;
    font-size: 14px;
    border-radius: 5px;
    background-color: #eeefef;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $recon-background-secondary;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .VehicleDetails-Mobile-tags {
    width: 90%;
    margin: auto 10px 10px auto;

    .TagList {
      justify-content: center !important;
    }
  }
}

.VehicleDetails-step-duration-goal {
  margin-top: 2px;
  padding-left: $spacing-md;
  color: rgba(38, 50, 56, 0.6);
  white-space: nowrap;

  .slash {
    padding: 0 3px;
  }
}

.VehicleDetails-step-info {
  min-width: 40%;

  .StepDropdown,
  .UsersDropdown {
    flex: 1 1 0;
    flex-wrap: nowrap;
    min-width: 0;
  }

  .UsersDropdown {
    margin-top: -1px;
  }
}

.mobile-viewport {
  .VehicleDetails {
    background: $recon-white !important;
  }
}

.VehicleDetails-section {
  display: flex;
  width: inherit;
  align-items: flex-start;
}

.VehicleDetails-sold {
  opacity: 0.6;
}

.FlatMobileVDP {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $light;
  z-index: 1555;
}
