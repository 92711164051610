@import '../../../../scss/variables';

$notification-icon-size: 32px;
$notification-padding: $spacing-md;

.NotificationIcon {
  transition: opacity 0.5s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: $notification-icon-size;
  height: $notification-icon-size;
  text-align: center;
  left: $notification-padding;

  .NotificationListItem.is-deleting .NotificationListItem-icon {
    opacity: 0.3 !important;
  }
}
