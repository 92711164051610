@import "scss/variables";
@import "scss/theme/variables";

.VehicleCardMobile {
  display: flex;
  flex-direction: column;

  .VehicleCardMobile-vehicleInfo {
    display: flex;

    .VehicleImageView-showroom-size {
      height: 69px;
      width: 92px;

      svg {
        width: 69px;
      }
    }

    .VehicleCard-year-make-model {
      margin-left: $spacing-md;
      flex-grow: 1;
    }
  }

  .VehicleCard-details-table {
    margin: $spacing-sm 0;
  }

  .TagListSrp {
    height: 40px;
    flex-wrap: wrap;

    li {
      padding: 1px $spacing-md 1px 0;
    }
  }

  .VehicleCardProgressBar {
    margin: 4px -16px;

    .VehicleCard-step-duration-goal {
      display: none;
    }
  }

  .VehicleCard-recon-inventory-tabs {
    flex-grow: 1;
    min-height: 262px;

    .VehicleCard-recon-inventory-tab-content {
      .VehicleCard-tab-container {
        overflow: hidden;
      }
    }

    .VehicleCard-vendorTasks-container {
      max-height: 145px;
    }

    .VehicleCardNotes-notes {
      max-height: 145px;
    }
  }
}
