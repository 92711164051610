.NotesListSection {
  width: 100%;
  max-width: 1032px;
  padding-left: 16px;

  .NotesListSection-date-label {
    letter-spacing: 1px;
    font-weight: 600;
    color: rgba(38, 50, 56, 0.6);
  }
}
