@import "../../../scss/variables";

.report-flex-columns {
  display: flex;
  flex-direction: row;
}

.ReportTitleRow {
  padding: $spacing-md $spacing-lg;

  .sub-title-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-right: 3px;
  }

  .sub-title-text {
    color: $recon-secondary;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    padding-right: 2px;
  }

  .evenly-spaced-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
