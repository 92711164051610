@import "scss/variables";
@import "scss/theme/variables";

.VehicleCardMobile {
  display: flex;
  flex-direction: column;

  .VehicleCard-recon-inventory-tab-content {
    height: auto !important;
  }

  .VehicleCardMobile-vehicleInfo {
    display: flex;

    .VehicleImageView-showroom-size {
      height: 69px;
      width: 92px;

      svg {
        width: 69px;
      }
    }

    .VehicleCard-year-make-model {
      margin-left: $spacing-md;
      flex-grow: 1;
    }
  }

  .VehicleCard-details-table {
    margin: $spacing-sm 0;
  }

  .VehicleCard-vendorTasks {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 250px;

    .VehicleCard-vendorTasks-container {
      margin-top: 8px;
      overflow-y: auto;

      .Vehicle-vendorTasks-table {
        min-width: 250px;

        thead {
          display: none;
        }

        .MuiTableCell-sizeSmall {
          padding-left: 0;
        }

        .MuiTableCell-sizeSmall:last-child {
          padding-right: 0;
        }
      }

      .Vehicle-vendorTasks-tableBold {
        font-weight: bold;
      }

      .Vehicle-vendorTasks-tableNoBorder {
        border-bottom-width: 0;
      }
    }

    .Vehicle-vendorTasks-buttonContainer {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;

      .Vehicle-vendorTasks-createButton {
        margin-right: 12px;
      }

      .Vehicle-vendorTasks-badgeContainer {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-end;
      }

      .VehicleCardTabFooter-badge {
        margin-left: 12px;
        position: relative;
        top: 4px;
      }
    }
  }
}
