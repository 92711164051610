@import "../../../scss/theme/variables.scss";
@import "../../../scss/variables";

.SendVehicleCard {
  cursor: pointer;
  overflow: hidden;

  &-info {
    height: 100px;
    border: 1px solid $primary;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px rgba(38, 50, 56, 0.24);

    &-image {
      width: 112px !important;
      height: 88px !important;
      font-size: 44px;
      color: rgb(158, 158, 158);
      margin: 0 16px;
    }

    &-car-details {
      flex: 1;
      font-style: normal;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.15px;
      color: $primary;

      &-column {
        display: flex;
        flex-direction: column;
        flex-grow: 0.5;

        @media screen and (max-width: $screen-sm-min) {
          flex-grow: 1;
        }

        &-title {
          padding-right: 10px;
        }
      }

      &-year-make {
        font-weight: 600;
      }

      &-stock-number {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.3px;
        color: $muted;
        display: block;
      }
    }

    &-icon {
      font-size: 24px;
      color: $primary;
      padding-right: 4px;
    }
  }
}
