@import "../../../../scss/theme/variables.scss";

.CheckboxInput {
  .checkbox-container {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .checkbox-container input {
    display: none;
  }

  /* Create a custom checkbox */
  .checkbox-checkmark {
    height: 1.25rem;
    width: 1.25rem;
    border-style: solid;
    border-width: 2px;
    border-color: $primary;
    border-radius: 3px;
    display: inline-block;
  }

  .checkbox-checkmark-lg {
    height: 1.5rem;
    width: 1.5rem;
  }

  .checkbox-checkmark-sm {
    height: 1rem;
    width: 1rem;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked input:disabled ~ .checkbox-checkmark {
    background-color: $primary;
    border-color: $primary;
    cursor: none;
  }

  .checkbox-container input:checked ~ .checkbox-checkmark {
    background-color: $primary !important;
    border-color: $primary;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkbox-checkmark::after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-container input:disabled input:checked ~ .checkbox-checkmark::after,
  .checkbox-container input:checked ~ .checkbox-checkmark::after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkbox-checkmark::after {
    position: relative;
    width: 6px;
    height: 11px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border: 2px solid $light;
    border-width: 0 2px 2px 0 !important;
    background-color: $primary;
    margin: 1px auto !important;
  }
}
