@import "../../../../scss/theme/variables.scss";

.RadioInput {
  .radio-container {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio */
  .radio-container input {
    display: none;
  }

  /* Create a custom radio */
  .radio-checkmark {
    height: 20px;
    width: 20px;
    border-style: solid;
    border-width: 2px;
    border-color: $muted;
    border-radius: 100%;
    display: inline-block;
  }

  /* When the radio is checked, add a blue background */
  .radio-container input:checked input:disabled ~ .radio-checkmark {
    background-color: $light;
    border-color: $success;
    cursor: none;
  }

  .radio-container input:checked ~ .radio-checkmark {
    background-color: $light !important;
    border-color: $success;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .radio-checkmark::after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .radio-container input:disabled input:checked ~ .radio-checkmark::after,
  .radio-container input:checked ~ .radio-checkmark::after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .radio-container .radio-checkmark::after {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: $success;
    border: 1px solid $success;
    margin: 3px auto;
  }

  .radio-icon {
    height: 20px;
  }
}
