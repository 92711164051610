@import "scss/variables";
@import "scss/theme/variables.scss";

.vendor-header {
  margin: 10px;
  display: flex;
  flex: 1;

  @media screen and (max-width: 600px) {
    .VehicleSearch-container {
      flex: 1;
      order: 0;
    }
  }
}
