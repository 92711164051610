@import '../../../../scss/variables';

.VINInquiryModal {
  padding-left: 8px !important;
  padding-right: 8px !important;
  .VINInquiryModal-body {
    padding-top: 0;

    .version-info {
      font-size: 10px;
      color: darkgrey;
    }
  }

  .VINInquiryModal-footer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .VINInquiryModal-buttons {
    display: flex;
  }

  .VINInquiryModal-text-input {
    border: 1px solid $recon-background-secondary;
    border-radius: 3px;
    height: 50px;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    padding-left: 8px;
  }

  .VINInquiryModal-form-label {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(38, 50, 56, 0.6);
    margin-top: 10px;
  }

  .VINInquiryModal-textarea {
    height: 124px !important;
    margin-top: 32px !important;
  }

  .VINInquiryModal-textarea-input {
    height: 104px !important;
  }

  .VINInquiryModal-error {
    color: $recon-error;
  }

  .VINInquiryModal-textarea-placeholder {
    bottom: 84px !important;
  }

  .VINInquiryModal-input-container {
    margin-top: 0;
  }

  .VINInquiryModal-cancel-button {
    border: none;
    margin-right: 20px;
    color: $recon-background-secondary;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    background-color: #fff;
  }

  .VINInquiryModal-open-sticker-button {
    outline: 1px #000;
    background-color: #fff;
    border-radius: 5px;
  }

  .VINInquiryModal-open-sticker-button-content {
    padding-left: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 5px;
    top: 50%;
  }

  .VINInquiryModal-submit-button {
    width: 83px;
    height: 48px;
    border-radius: 3px;
    background: $recon-background-secondary;
    box-shadow: 0 4px 8px rgba(38, 50, 56, 0.24);
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    margin: 2px;

    &:disabled {
      background-color: rgba(38, 50, 56, 0.12) !important;
      border-color: rgba(38, 50, 56, 0.12) !important;
      color: $recon-background-secondary !important;

      > div {
        opacity: 0.3;
      }
    }
  }
}
