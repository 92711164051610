@import "../../../scss/variables";
@import "../../../scss/theme/variables";

.Notes2 {
  background-color: #fafafa;
  font-size: 16px;

  &-Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-pin-button {
      background-color: transparent;
      border: none;
      padding: 0 !important;
    }
  }

  .section-header {
    background-color: $light;
    align-self: center;
    padding: $base-margin * 2 0;
    overflow: hidden;
  }

  .input-container {
    padding: 0 !important;
    border-top-width: 0.5px;
    border-top-style: solid;
    border-top-color: $recon-divider-color;

    fieldset,
    textarea {
      border: none !important;
    }
  }

  .note-container {
    padding: 0 $base-margin * 2;
  }

  .Notes-no-note-container {
    height: 100%;
    padding: 30px;
  }

  .no-note-title {
    color: $recon-grey !important;
  }

  .no-note-message {
    color: $recon-tertiary !important;
  }

  .notes-header {
    height: 10%;
  }

  .notes-desktop-header {
    background-color: $recon-background-tertiary !important;
  }

  .sectionList-container {
    width: 100%;
    max-width: 1032px;
    padding-left: 24px;
  }

  .mentionable-input-container {
    padding: 16px;
    background-color: $light;
    border: 1px solid rgba(38, 50, 56, 0.38);
    max-width: 1032px;
    border-radius: 5px;
    font-size: 16px;

    @media screen and (max-width: 575px) {
      border-radius: 0 !important;
      border: unset !important;
      border-top: 1px solid rgba(38, 50, 56, 0.38) !important;
    }
  }

  .input-focus {
    border: 1px solid $primary;
  }

  .icons-container {
    margin-top: 12px;

    .upload-icon {
      color: rgba(38, 50, 56, 0.6);
    }

    .send-icon {
      padding: 5px;
      margin-right: -5px;
      color: $info;
    }

    .disabled {
      color: rgba(38, 50, 56, 0.12);
    }
  }

  .person-icon-container {
    height: 40px;
    width: 40px;
  }

  .drop-shadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .VehicleImageView {
    background-size: cover;
    border-radius: 5px;
  }

  .pane-footer {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 21px;

    @media screen and (max-width: 575px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-bottom: 0 !important;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &-staged-file-padding {
    .pane-body {
      padding-bottom: 400px !important;
    }
  }

  .pane-body {
    padding-right: 16px;

    @media screen and (max-width: 575px) {
      padding-bottom: 255px;
    }
  }

  .author-title {
    font-weight: 600;
  }

  .preview-image {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-position: center center;
    background-size: cover;
    margin-left: 9px;
    position: relative;
  }

  .preview-icon {
    margin-left: 5px;
  }

  .image-uploading-container {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: rgba(38, 50, 56, 0.12);
    margin-left: 9px;
    position: relative;

    .spinner-border-sm {
      width: 20px;
      height: 20px;
      border-width: 0.1em;
    }
  }

  .remove-image-icon {
    font-size: 10;
    position: absolute;
    top: -3px;
    right: -3px;
    cursor: pointer;
  }

  .remove-image-icon-file {
    font-size: 10;
    margin-left: 5px;
    cursor: pointer;
  }

  .icons-container-with-image {
    margin-bottom: -8px;
  }

  .DraftEditor-root {
    max-height: 80px;
    overflow-y: scroll;
    max-width: 991px;
  }

  .DraftEditor-editorContainer,
  .public-DraftEditor-content {
    height: 100%;
  }

  @media screen and (max-width: 1366px) {
    .mentionable-input-container {
      max-width: none;
    }
  }

  @media screen and (max-width: 768px) {
    .sectionList-container {
      padding-left: 16px;
    }
  }
}

.Notes2-attachments {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100%);
  height: auto;
  padding-top: 16px;
  overflow-x: auto;
  overflow-y: hidden;

  &-container {
    margin-left: 16px;
    margin-right: 16px;
    max-width: 120px;

    .UploadPlaceholder {
      width: 120px !important;
      height: 90px !important;
    }
  }

  .Notes2-attachments-filename {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #000;
    white-space: wrap;
    overflow-wrap: break-word;
  }

  .Notes2-attachments-filesize {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #000;
  }
}
