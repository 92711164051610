@import '../../../scss/variables.scss';

$max-width: 180px;
$icon-size: 12px;
$right-padding: $icon-size + 2px;

.StepDropdown {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  text-overflow: ellipsis;

  .StepDropdown-btn.dropdown {
    display: block;
    max-width: 100%;

    .dropdown-toggle.btn {
      max-width: 100%;
      overflow: hidden;
      background: url(../chevron-down.svg) no-repeat;
      background-size: $icon-size $icon-size;
      background-color: transparent;
      background-position: right 7px;
      border: none;
      padding: 0 $right-padding 0 0 !important;
      font-size: 16px !important;
      box-shadow: none;
      text-align: left;
      text-transform: none;
      text-overflow: ellipsis;

      svg {
        display: none;
        position: relative;
        top: -2px;
        margin: 0 !important;
      }

      .StepDropdown-expand-icon {
        fill: $recon-dropdown-arrow-color;
      }

      &::after {
        border: none;
      }

      &:active {
        background-color: transparent !important;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}
