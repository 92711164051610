@import "scss/theme/variables.scss";
@import "scss/variables.scss";

.VANotificationSystem-container .notification {
  height: auto !important;
  padding: 10px 0 10px 10px;
}

.VANotificationSystem-container .notification-message {
  height: auto;
  max-height: 80px;
  overflow-y: auto;
}
