@import "../../../../scss/variables.scss";
@import "../../../../scss/mixins.scss";

.Banner-container {
  width: 100%;
  padding: 20px 20px 0;

  &-inventory {
    padding: 20px 20px 0;
    margin-top: -4px;
  }

  @include xs {
    padding: 20px 10px 10px;
  }

  @media screen and (max-height: 576px) {
    padding: 20px 10px 20px;
  }
}

.Banner {
  padding: 24px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;

  &-icon {
    color: $recon-icon-color;
    margin-right: 24px;
  }

  &-title {
    font-size: 20px;
    margin-bottom: 8px;
  }

  &-subtitle {
    font-size: 14px;
  }
}
