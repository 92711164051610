@import "../../../../scss/variables";

.CreateTagModal {
  .CreateTagModal-body {
    padding-top: 0;
  }

  .CreateTagModal-footer {
    margin: -5px 2px;
  }

  .CreateTagModal-text-input {
    border: 1px solid $recon-background-secondary;
    border-radius: 3px;
    height: 50px;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    padding-left: 8px;

    &:disabled {
      background-color: hsl(0, 0%, 95%);
    }
  }

  .CreateTagModal-option-container {
    display: flex;
    flex-direction: row;
  }

  .CreateTagModal-circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
  }

  .CreateTagModal-color-circle {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    border: 1px solid $recon-white;
  }

  .CreateTagModal-single-value {
    left: 35px;
  }

  .CreateTagModal-single-value-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .CreateTagModal-cancel-button {
    border: none;
    margin-right: 20px;
    color: $recon-background-secondary;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    background-color: #fff;
  }

  .CreateTagModal-btn-submitting {
    background-color: $recon-background-secondary !important;
  }

  .CreateTagModal-btn-disabled {
    background-color: rgba(38, 50, 56, 0.12) !important;
    border-color: rgba(38, 50, 56, 0.12) !important;
    color: $recon-background-secondary !important;

    > div {
      opacity: 0.3;
    }
  }

  .CreateTagModal-create-button {
    width: 83px;
    height: 48px;
    border-radius: 3px;
    background: $recon-background-secondary;
    box-shadow: 0 4px 8px rgba(38, 50, 56, 0.24);
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    margin: 2px;
  }

  .CreateTagModal-dropdown-label {
    margin-top: 20px;
  }
}
