@import '../../../../scss/theme/variables.scss';
@import '../../../../scss/variables.scss';

div.engage-group-header-row {
  cursor: pointer;
  display: grid;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  padding: 5px 0px;
  border-top: 1px solid rgb(222, 226, 230);
  grid-template-columns:
    6fr
    repeat(3, minmax(40px, 130px))
    135px;
  align-items: center;
  &:hover {
    background-color: rgba(222, 226, 230, 0.1);
  }
  &.current-row {
    background-color: #fafafafa;
    &:hover {
      background-color: #f3f3f3;
    }
  }
  &.sub-row {
    cursor: default;
    grid-template-columns: repeat(3, 1fr);
  }
  &.helper {
    grid-template-columns: 1fr;
    padding: 4rem 2rem;
    min-height: 100px;
    text-align: center;
    background-color: #fff;
  }
}

.group-label-shopper-contact {
  padding-left: 15px;
}
.group-label-shopper-contact-divider {
  padding-left: 5px;
  padding-right: 5px;
}

.group-label-vehicle-year-make-model {
  padding-left: 20px;
  padding-right: 10px;
}

.group-label-vehicle-link {
  color: $info;
  text-decoration: underline;
}

.view-duration-total-text {
  color: $recon-dark-grey;
  font-size: 12px;
  padding-left: 3px;
}