@import "../../../../scss/variables";

.VehicleHeader {
  min-height: 112px;
  height: 112px;
  left: 0;
  top: 64px;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .VehicleHeader-col {
    margin: 32px 16px 0 0;
    flex-grow: 0;
    min-width: 110px;
    max-width: 110px;

    .VehicleTitle {
      font-family: $recon-font-primary;
      font-style: $recon-font-style;
      font-size: 16px;
      line-height: $spacing-slg;
      letter-spacing: $recon-letter-spacing;
      color: $recon-background-secondary;
    }

    .VehicleTitle .year-make {
      font-weight: $recon-medium;
    }

    .VehicleTitle .model {
      font-weight: normal;
      font-size: 16px;
      line-height: $spacing-slg;
      letter-spacing: $recon-letter-spacing;
    }
  }

  @media screen and (max-width: $screen-md-min) {
    .VehicleHeader-col {
      margin-top: 16px !important;
    }
  }

  .VehicleHeader-back-container {
    margin: 12px 0 0 8px;
  }

  .VehicleHeader-image-container {
    position: relative;
    margin: 15px 16px 0 16px;
    height: 90px;
    max-width: 160px;
    padding: 0;
    flex-grow: 0;
  }

  .VehicleInfoItems-dropdown {
    position: absolute;
    right: 16px;
    top: 15px;
  }

  .VehicleInfoItems-recon-dropdown {
    margin-left: -130px !important;
    margin-top: 30px !important;

    .loadingIconAlongside {
      display: flex;
    }
  }

  .dropdown-menu.show {
    opacity: 1 !important;
    pointer-events: auto !important;
  }

  .VehicleHeader-step-button {
    display: flex;
    align-content: row;
    align-items: flex-end;
    margin-bottom: 15px;
    margin-right: 15px;
    padding-right: 10px;
    .StepButtons-pause-col {
      display: flex;
      align-content: row;
      padding-right: 10px;
      width: 160px;
    }
  }
}

@media screen and (max-width: 1291px) {
  .VehicleHeader {
    min-height: 166px;
    height: 166px;

    .VehicleHeader-col {
      min-width: 170px;
      max-width: 170px;
    }
  }
}

@media screen and (max-width: 902px) {
  .VehicleHeader {
    min-height: 200px;
    height: 200px;
  }
}

@media screen and (max-width: 612px) {
  .VehicleHeader {
    min-height: 260px;
    height: 260px;
  }
}
