@import "../../../../../scss/theme/variables.scss";

.Opens {
  width: 100%;

  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: $primary;
    margin-bottom: 8px;
  }

  &-empty-title {
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: $muted;
    margin-bottom: 8px;
  }

  &-chart {
    width: 100%;
    overflow-y: auto;
  }
  .Opens-chart{
    width:100%;
    height:100%;
  }
  @media screen and (max-width: 575px) {
    padding-bottom: 95px;
  }
}
