$primary: #263238;
$secondary:#263238;
$success: #1ab245;
$warning: #ffab00;
$danger: #d32f2f;
$light: #fff;
$dark: #263238;
$info: #007cd7;
$muted: rgba(38, 50, 56, 0.6);

$theme-colors: (
  "primary":    $primary,
  "success":    $success,
  "secondary":  $secondary,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);

$font-family-sans-serif: "Source Sans Pro", "Open Sans", sans-serif !default;
$font-size-base: .875rem !default;

$font-weights: ( 100, 200, 300, 400, 500, 600, 700, 800, 900 );
