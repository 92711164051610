@import "scss/theme/variables.scss";
@import "scss/variables.scss";

.VehicleUpdate-container {
  display: flex;
  justify-content: space-between;
  padding: $spacing-sm 0;

  .VehicleUpdate-action {
    cursor: pointer;
    text-decoration: underline;
  }
}
