@import "scss/variables";

.CustomDatePickerModal {
  width: fit-content !important;
}

.CustomDatePickerModal-body {
  width: fit-content;
  padding: 0;
  box-shadow: 0 rgba(255, 255, 255, 0.08);
}

.CustomDatePickerModal-cancel-button {
  margin-right: 20px;
  border-width: 0;
}

.CustomDatePickerModal-ok-button {
  width: 64px;
  border-radius: 2px;
}

.CustomDatePickerModal-footer {
  z-index: 1000;
  background-color: white;
  text-align: right;
  padding: 16px;
}

.rdrDayToday .rdrDayNumber span::after {
  background-color: $recon-secondary;
}
