@import "scss/variables";
@import "scss/theme/variables.scss";

.vendor-pay-invoice {
  display: flex;
  flex-direction: column;
  flex: 1;

  .vendor-pay-invoice-content {
    flex: 1;
    overflow: auto;
    background-color: #cfcfcf;
  }

  .vendor-pay-invoice-header {
    padding: 16px;
  }

  .vendor-pay-invoice-footer {
    padding: 16px;
    background-color: $recon-page-background;
  }
}
