@import "scss/variables";

$tag-list-min-height: 25px;

.TagList-Container-small,
.TagList-Container-mobile {
  display: flex;
  justify-content: flex-start;
  width: 95%;
  margin: 8px 0 6px 0;

  .TagListSrp {
    .TagListSrp-icon-button {
      height: 25px;
      width: 25px;

      .TagListSrp-icon {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.TagList-Container {
  display: flex;
  justify-content: flex-start;
  font-size: 17px;
  min-height: $tag-list-min-height;
  max-height: $tag-list-min-height;
  height: $tag-list-min-height;
  width: 90%;

  .TagList {
    width: 100%;
    min-height: $tag-list-min-height;
    max-height: $tag-list-min-height;
    height: $tag-list-min-height;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    & li:first-of-type > div {
      margin-left: 0;
    }

    .TagComponent-tag {
      margin-bottom: 5px;
    }
  }

  .TagList-icon-button {
    height: 24px;
    border: none;
    background-color: $recon-white;
    position: relative;
    margin-bottom: 5px;
  }

  .TagList-icon {
    height: 23px;
    border: none;
    font-size: 20px;
    color: rgba(38, 50, 56, 0.48);
    background-color: $recon-white;
  }

  .TagList-empty-container {
    font-size: 12px;
    color: rgba(38, 50, 56, 0.48);
    vertical-align: bottom;
    background-color: #fff;
    position: relative;
    margin-left: -5px;
    border: none;
    min-width: 75px;
    max-height: 32px;
  }
}

.TagList-tags-row-truncate {
  overflow: hidden;
  min-height: $tag-list-min-height;
  max-height: $tag-list-min-height;
}
