.VehicleMenu {
  padding: 8px 8px 0 8px;

  .menu-chevron-icon {
    color: rgba(38, 50, 56, 0.38);
  }
}

.VehicleMenu.notCollapsed {
  width: 260px;

  .VehicleMenuRow-flat {
    .VehicleMenuRow-icon {
      margin: 0 8px 0 11px;
    }

    .VehicleMenuRow-chip {
      .MuiChip-root {
        margin-right: 8px;
      }
    }
  }
}

.VehicleMenu.collapsed {
  width: 61px;

  .VehicleMenuRow-flat {
    width: 44px;
    align-self: center;
    align-content: center;

    .VehicleMenuRow-chip {
      .MuiChip-root {
        height: 8px;
        width: 8px;
        margin-left: -16px;
        margin-top: -10px;

        .MuiChip-label {
          visibility: hidden;
        }
      }
    }
  }
}
