@import "../../../../scss/variables";

.DealerStepReport {
  .ReportContainer-overview {
    .card {
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-top: 0;
    }
  }

  .card-body {
    padding: 0;
  }

  .card-header {
    padding: 0;
    border-bottom: 0;
    margin-bottom: 0;
    background-color: unset;
  }

  .DealerStepReport-title {
    font-weight: $recon-medium;
    font-size: 20px;
    padding: $spacing-md 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .DealerStepReport-Overview {
    display: flex;
    flex-direction: column;
    width: 100%;

    .DealerStepReport-Overview-Items {
      display: flex;
    }
  }

  .DealerStepReport-SummaryCard-container {
    border: #e0e0e0 solid thin;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    flex-grow: 1;
    margin: 5px;
    max-width: 340px;
    min-width: 140px;

    .SummaryCard-base-stat-value-text {
      font-size: 24px;
    }

    .DealerStepReport-QuadrantCard {
      display: flex;
      flex-wrap: wrap;
      padding: $spacing-lg;

      .DealerStepReport-Quadrant {
        padding: $spacing-md;
        width: 50%;
        text-align: center;
        align-content: center;

        h4 {
          margin: 0 0 5px;
        }
        .DealerStepReport-quadrant-good {
          color: $recon-success;
        }
        .DealerStepReport-quadrant-bad {
          color: $recon-error;
        }
        .DealerStepReport-quadrant-neutral {
          color: $recon-secondary;
        }
      }
    }
  }

  .DealerStepReport-StepSummary {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .DealerStepReport-StepSummary-summary-row-header {
    padding: $spacing-md $spacing-lg;
    display: flex;
    width: 100%;

    .evenly-spaced-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .label.recon {
      max-width: 125px;
    }

    .label.retail {
      max-width: 150px;
    }
  }

  .DealerStepReport-StepSummary-summary-row {
    padding: 0 $spacing-lg;
    min-height: 45px;
    display: flex;
    width: 100%;
    align-items: center;

    .evenly-spaced-item {
      display: flex;
      flex: 1 1;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      .goal {
        color: $recon-alt-text;
      }
    }

    .selectable {
      cursor: pointer;
      text-decoration: underline;
    }

    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
      margin-left: -5px;
      margin-right: 5px;
    }

    .border-bottom {
      border-bottom: #f1f1f1 solid 1px;
    }

    .early {
      color: #00c761;
    }

    .overdue {
      color: $recon-danger;
    }
  }

  .DealerStepReport-StepSummary-summary-row.can-hover {
    &:hover {
      background-color: $recon-hover;
      cursor: pointer;
    }
  }

  .DealerStepReport-StepSummary-step {
    font-weight: 500;
    font-size: medium;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
