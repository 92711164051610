@import "scss/variables.scss";

.message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.HistoryBasedValue {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;

  .value-display {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2% 0;
    border-bottom: 1px solid $recon-divider-color;
    margin-bottom: 25px;

    .arrows {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      .arrow {
        padding: 5px;
        width: 160px;
        display: inline-block;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        float: none;
        vertical-align: center;

        &-direction {
          width: 100%;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;

          > img {
            width: 86px;
            vertical-align: middle;
          }
        }

        &-icon {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          > img {
            width: 32px;
            margin: 12px 0;
            vertical-align: middle;
          }
        }
        &-text {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
        }
      }
    }

    .value {
      font-size: 24px;
      display: flex;
      flex-direction: column;

      > div {
        width: 100%;
        text-align: center;
      }
    }
  }

  .controls-mobile {
    width: 200px;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: inline;
    padding-bottom: 70px;
    margin: 0 auto;

    &-values {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      gap: 40px;
    }
    &-options {
      width: 100%;
      display: inline;
      .trim {
        grid-area: trim;
        min-width: 100%;
        max-width: 300px;
        transition: width 0.25s;
      }
      .color {
        grid-area: trim;
        min-width: 100%;
        max-width: 300px;
      }
    }
  }

  .controls-desktop {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-values {
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    &-options {
      width: 50%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-template-areas:
        "trim color"
        "zipCode options"
        "submit submit";
      gap: 20px;

      .zipCode,
      .color,
      .trim,
      .options {
        padding: 0 25px;
      }

      .zipCode {
        grid-area: zipCode;
      }
      .color {
        grid-area: color;
      }
      .trim {
        grid-area: trim;
      }
      .options {
        grid-area: options;
      }
      .submit {
        grid-area: submit;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
