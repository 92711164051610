@import "../../../../scss/theme/variables.scss";
@import "../../../../scss/variables.scss";

.VelocityEngageModuleReport {
    .column-label {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #263238;
        text-transform: uppercase;
      }
    .ReportTitleRow {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: #fafafafa;
        text-transform: uppercase;
        @extend .column-label;
        border: 1px solid rgb(222, 226, 230);
    }
    .TagComponent-tag {
        margin-right: 0;
    }
    .ReportRow{
        height: 91px;
    }
    [data-name=Vehicle] {
        flex: 0 0 145px;
        .stockNumber, .vin {
            font-size: 16px;
        }
        .yearMakeModel{
            color: $muted;
        }
    }
    [data-name=age] {
        flex: 0 0 65px;
    }
    [data-name=stockStatus] {
        flex: 0 0 65px;
        padding-right: 30px
    }
    .border-bottom {
        border-bottom: 0px !important;
    }
    .card {
        margin-bottom: -1px;
        border-radius: 0;
    }
    .footer {
        display:flex;
        justify-content: flex-end;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 20px;
        background-color: #fafafafa;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .ReportCellContent {
        font-weight: normal !important;
    }
    .download-menu-container {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
    }
}