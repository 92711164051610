@import "./variables";

@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) and (max-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) and (max-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$screen-sm-min}) and (max-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin xs {
  @media (min-width: 0) and (max-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin xs-landscape {
  @media (min-height: 0) and (max-height: #{$screen-sm-min}) and (orientation: landscape) {
    @content;
  }
}
