@import '../../../../../../scss/variables';

.StepVehicleCounts {
  display: flex;
  flex-direction: row;
  color: #bbb;
  padding-right: 8px;

  > * {
    width: 40px;
    text-align: center;
  }

  .status-ok {
    color: $recon-ok;
  }

  .status-warning {
    color: $recon-warning;
  }

  .status-danger {
    color: $recon-danger;
  }

  .all-status-text {
    font-weight: bold;
  }

  .zero {
    color: $recon-grey !important;
  }

  .status-block {
    &:hover {
      background-color: $recon-hover;
      font-weight: bold;
    }

    &.selected {
      border: 1px solid #c00;
      background-color: $recon-hover;
      z-index: 200;
      margin-top: 1px;
    }
  }
}
