@import "../../../scss/variables";
@import "../../../scss/theme/variables.scss";

.SendVehicleModal {
  &-body {
    overflow-y: auto;
  }

  &-form {
    &-column {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &-row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: auto;
      align-items: baseline;
      align-content: flex-start;

      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }

      .SendVehicleCard {
        width: 100%;
      }

      .DraftEditor-editorContainer {
        z-index: 0 !important;
      }

      &-select {
        padding-right: 20px;
        width: 540px;

        @media screen and (max-width: 768px) {
          padding-right: 0;
          width: 100%;
        }
      }

      .Checkbox {
        margin: 0 10px 0 0;
        padding: 14px 18px;
      }

      .TextInput {
        border-color: hsl(0, 0%, 80%) !important;
        width: 100%;
        margin: 0 18px 18px 0;
        color: #263238;

        // leave floating label in focused position
        .floating-label {
          font-size: 12px;
          left: 11px;
          bottom: 43px;
          background-color: $recon-white;
          opacity: 1;
          color: rgba(38, 50, 56, 0.5);
        }

        .SendVehicleModal-form-input {
          width: 100% !important;
        }
      }
    }

    &-checkbox {
      &-text {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: $recon-background-secondary;
      }
    }

    &-input {
      width: auto;
    }

    &-radio-text.disabled {
      .MuiIconButton-label {
        color: hsl(0, 0%, 90%);
      }
    }

    &-radio-text.hidden {
      display: none;
    }
  }

  &-htmloutput {
    width: 100%;
    height: 150px;
  }

  &-wrapper {
    margin-bottom: 20px;

    .rdw-editor-main {
      padding: 6px 19px 6px;
      border-radius: 2px;
      border: 1px solid #f1f1f1;
    }

    .rdw-editor-toolbar {
      margin-bottom: -1px;
    }
  }

  .SendVehicleDialog {
    max-width: 874px;
    height: 75vh;

    @media screen and (max-width: 874px) {
      width: 95vw;
      min-width: 95vw;
    }

    .modal-header,
    .modal-body,
    .modal-footer {
      margin: 0;
      padding: 14px 24px;
    }

    .modal-header {
      box-shadow: inset 0 -1px 0 #e6e7e8;
    }

    .modal-title {
      font-weight: 600;
    }

    .modal-content {
      height: 100%;
    }
  }

  .SendVehicleModal-body {
    padding-top: 0;

    .version-info {
      font-size: 10px;
      color: darkgrey;
    }
  }

  .SendVehicleModal-footer {
    margin: -5px;
  }

  .SendVehicleModal-cancel-button {
    border: none;
    margin-right: 20px;
    color: $recon-background-secondary;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    background-color: #fff;
  }

  .SendVehicleModal-submit-button {
    width: 83px;
    height: 48px;
    border-radius: 3px;
    background: $recon-background-secondary;
    box-shadow: 0 4px 8px rgba(38, 50, 56, 0.24);
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    margin: 2px;

    &:disabled {
      background-color: rgba(38, 50, 56, 0.12) !important;
      border-color: rgba(38, 50, 56, 0.12) !important;
      color: $recon-background-secondary !important;

      .div {
        opacity: 0.3;
      }
    }
  }

  .SendVehicleModal-add-contact-button {
    border: 1px solid $recon-alt-text !important;
    border-radius: 5px !important;
    margin: 0 0 4px;

    &.btn-secondary {
      &:hover,
      &:focus,
      &.focus,
      &:active,
      &:active:focus,
      &.active {
        border: 1px solid $recon-alt-text !important;
        border-radius: 5px !important;
      }
    }
  }

  .SendVehicleModal-shopper-row {
    .Button {
      padding-top: 16px;
    }
  }
}

.Send-to-shopper-button.btn.btn-block {
  padding: 7px 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: $primary;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  border: 1px solid $primary !important;

  .vector-icon {
    height: 12px;
    margin-left: 14px;
    fill: $primary !important;
  }

  @media screen and (max-width: 768px) {
    min-width: unset;
    width: 25px !important;

    .vector-icon {
      height: 12px;
      margin: 3px 0 !important;
    }
  }
}
