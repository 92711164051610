@import "../../../../scss/theme/variables.scss";

.MobileVDPHeader {
  padding-right: 24px;
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative;
  display: flex;

  .box {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box:first-child > div { margin-right: auto; }
  .box:last-child > div { margin-left: auto; }

  &-close {
    padding-left: 29px;
    justify-content: flex-start !important;
  }

  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: $primary;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;

    &-icon {
      margin-right: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &-menu {
    width: 100vw;
    background-color: $light;
    overflow: hidden;
    position: absolute;
    top: 74px;
    z-index: 1555;
    box-shadow: 0 32px 32px rgba(38, 50, 56, 0.32);
    border-radius: 0 0 8px 8px;

    &-divider {
      width: 100%;
      height: 1px;
      background-color: #e6e7e8;
      margin-top: 14px;
    }
  }

  &-menuClosed {
    display: none;
    height: 0;
    transition: height 300ms ease-in;
  }

  &-menuOpen {
    display: block;
    height: 375px;
    transition: height 300ms ease-in;
  }

  &-menu-button {
    justify-content: flex-end !important;
  }

  .UploadManager {
    margin-right: 10px;
  }
}
