@import '../../../../scss/variables';
@import '../../../../scss/theme/variables.scss';

.NotesMessage {
  align-items: flex-start;
  margin: $base-margin 0 !important;
  padding-left: 0 !important;

  .NotesMessage-time {
    white-space: nowrap;
  }

  .NotesMessage-truncated-text {
    width: 153px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .NotesMessage-note-content {
    word-break: break-word;
  }
}

.current-user-message-container {
  align-items: flex-end !important;
  margin: $base-margin 0 !important;
}

.current-user-message {
  background-color: $recon-background-secondary !important;
  color: $recon-white !important;
  max-width: 60%;
  padding: $base-margin * 2;
  border-radius: 24px !important;
  border-top-right-radius: 0 !important;
  word-wrap: break-word;
}

.other-user-message {
  background-color: $recon-background-tertiary !important;
  color: $recon-secondary;
  max-width: 60%;
  padding: $base-margin * 2;
  border-radius: 24px !important;
  border-top-left-radius: 0 !important;
  word-wrap: break-word;
}

.edit-button {
  border-radius: 13px !important;
  border-style: solid !important;
  border-width: 0.5px !important;
  border-color: $recon-secondary !important;
  padding: 0 !important;
  min-height: 0 !important;
  margin-left: $base-margin !important;
  color: $recon-secondary;
}

.caption-container {
  margin-bottom: calc($base-margin / 2);
  align-items: flex-start;
}

.mention-item {
  color: $brandLinkColor !important;
  text-decoration: underline !important;
}

.display-note-document {
  padding: 10px;
  outline: 0.5px solid #f0f0f0;
  cursor: pointer;
}

.NotesMessage-attachments {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: calc(100%);
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 12px;

  &-container {
    width: 120px;
    height: auto;
    margin-right: 16px;
    margin-bottom: 12px;

    &:first-child {
      .ThreeDotMenu-menu {
        right: unset !important;
        left: 28px !important;
        z-index: 100;
      }
    }

    .UploadPlaceholder {
      width: 120px !important;
      height: 90px !important;
      margin-top: 10px !important;
    }
  }

  .NotesMessage-attachments-filename {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #000;
    overflow-wrap: break-word;
  }

  .NotesMessage-attachments-filesize {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #000;
  }
}

.NoteMessage-video {
  width: 120px;
  height: 75px;
  border-radius: 5px;

  video {
    width: 120px;
    height: 75px;
  }
}

.ThreeDotMenu {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: rgba(38, 50, 56, 0.38);
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiSvgIcon-root {
    fill: $light;
  }

  &-menu {
    position: absolute;
    top: 28px;
    right: 28px;
    background-color: $light;
    box-shadow: 0 8px 8px rgba(38, 50, 56, 0.32);
    border-radius: 3px;
    padding: 16px;
    z-index: 100;

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  &-icon {
    font-size: 13px !important;
    fill: $primary !important;
    margin-right: 16px;
  }

  &-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: $primary;
    white-space: nowrap;
  }
}

.ThreeDotMenu-highlight {
  background-color: $primary;
}

.NoteAttachmentView-image-container {
  position: relative;
}
