@import "../../../../scss/variables.scss";

.DocumentRow {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  box-shadow: inset 0 -1px 0 #eff1f1;

  &-icon-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
  }

  &-file-info {
    margin: 12px 8px 12px 16px;
    min-width: 0;

    .DocumentRow-title {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.3px;
      color: $recon-background-secondary;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .DocumentRow-subtitle {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.3px;
      color: $recon-alt-text;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-delete-button {
    fill: $recon-background-secondary !important;
    margin: 16px 12px;
    margin-left: auto;
  }

  &:first-child {
    padding-top: 0 !important;
  }

  &-loading-spinner {
    & > div {
      margin-top: 0 !important;

      & > div {
        width: 20px !important;
        height: 20px !important;
        border-width: 2px;
      }
    }
  }
}
