@import "../../../../../scss/theme/variables.scss";

.FilterChip {
  background-color: $primary;
  padding: 4px 5px 4px 12px;
  border-radius: 68px;
  color: $light;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 4px;
  margin-bottom: 10px;
  overflow: hidden;

  &-title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-icon {
    font-size: 18px !important;
  }
}
