@import "../../../scss/mixins.scss";
@import "../../../scss/theme/variables.scss";

@mixin drop-shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.VehicleCollectionBrowser {
  background: #eceeee;

  .VehicleCard2-selected {
    position: absolute;
    top: 0;
    right: 0 !important;
    bottom: 0;
    left: 0 !important;
    pointer-events: none;
    border: 1px solid $primary;
    border-radius: 5px !important;

    @media screen  and (max-width: 1023px) {
      border: 0 none;
      border-radius: 0 !important;
    }
  }

  .VehicleCard-sold {
    .col-details {
      opacity: 0.3;
    }

    .VehicleImageView {
      opacity: 0.3;
    }
  }

  .VehicleSearch {
    @include xs {
      margin-top: 12px !important;
    }
  }

  .Sidebar {
    @include drop-shadow;
  }

  .grey-placeholder {
    background-color: #f2f2f2;
  }

  .vertical-scroll {
    overflow-y: auto;
  }

  .VehicleCollectionBrowser-preview {
    display: none;
    width: 320px;

    @media screen and (min-width: 1024px) {
      display: block !important;
    }

    @media screen and (min-width: 1200px) {
      width: 375px;
    }
  }
}
