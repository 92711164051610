@import "../../../../scss/variables";

.SyndicationModal {
  &-upload {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-file {
      padding-left: 16px;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.3px;
      color: #000;

      &-delete-button {
        margin-left: 29px;
        border: none;
        background-color: transparent;

        &-icon {
          color: $recon-icon-color;
        }
      }
    }
  }

  &-text {
    padding-top: 32px;
    padding-bottom: 12px;
  }

  &-gallery {
    -ms-overflow-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-height: 240px;
    overflow-y: scroll;

    .VehicleImageView {
      width: 48px;
      height: 48px;
      margin: 6px;
    }
  }

  &-gallery::-webkit-scrollbar {
    display: none;
  }

  &-gallery-button {
    border: none !important;
    background-color: transparent;
    padding: 0 !important;
  }

  .disabled {
    opacity: 0.32;
  }

  .active {
    border: 2px solid #d32f2f !important;
  }

  .inactive {
    opacity: 0.6;
  }
}
