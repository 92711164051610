@import "../../../scss/variables";

.PaneHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  min-height: 64px;
  width: 100%;
  position: relative;
  padding: $spacing-md;

  .ResponsiveButton {
    margin-right: -$spacing-md;

    .MuiSvgIcon-root {
      font-size: 20px;
    }
  }
}

.PaneHeader-icon {
  margin-right: $spacing-md;
}

.PaneHeader-icon.mobile,
.mobile-viewport .PaneHeader-icon.responsive {
  display: none;
}

.PaneHeader-title,
.PaneHeader-action-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.PaneHeader-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  font-family: $font-family-sans-serif;
  letter-spacing: 0.15px;
  color: $recon-background-secondary;
  overflow: hidden;
  text-overflow: ellipsis;
}
// Desktop

.PaneHeader.desktop,
.desktop-viewport .PaneHeader.responsive {
  padding: $spacing-md $spacing-slg;
  background-color: $recon-white !important;
}

.PaneHeader-back-button.desktop,
.desktop-viewport .PaneHeader-back-button.responsive {
  display: none;
}

// Mobile

.PaneHeader.mobile,
.mobile-viewport .PaneHeader.responsive {
  height: 50px;
  min-height: 50px;
  padding: $spacing-md $spacing-sm;
}

.PaneHeader-title.mobile,
.mobile-viewport .PaneHeader-title.responsive {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 65%;
  font-size: 20px;
}

.PaneHeader-action-buttons.mobile,
.mobile-viewport .PaneHeader-action-buttons.responsive {
  min-width: 48px;
}
