@import "../../../../../scss/variables";
@import "../../../../../scss/theme/variables.scss";

.FilterRow {
  &-card {
    border-top: none !important;
    border-right: none !important;
    border-radius: none !important;
  }

  &-title {
    width: 100px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  &-container {
    display: flex;
    flex-direction: row;
    height: 52px;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
  }

  &-select-dropdown {
    padding: 5px 0px 5px 10px;
    max-width: 210px;

    &-selected {
      color: rgba(38,50,56,0.6);
    }
  }

  .DropdownButton-selectedOption {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.3px !important;
    color: $primary !important;
    display: flex;
    flex-direction: row;
  }
}
