@import "../../../scss/variables.scss";
@import "../../../scss/theme/variables.scss";

.ReconVelocityOverview {
  padding: 0 !important;
  padding-bottom: 115px !important;
  margin: 0 !important;
  max-width: none !important;

  .ReconProgressSection-step-button {
    .step-button-base,
    .complete-button-base {
      width: 100% !important;
    }
  }

  &-separator {
    border-left: 1px solid #e6e7e8;
    padding-left: 16px !important;

    @media screen and (max-width: 991px) {
      border: none !important;
      padding-left: 0 !important;
    }
  }
}
