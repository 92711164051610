@import "../../../../scss/variables.scss";

.FlatMediaSection {
  .Gallery-empty {
    padding-top: 0 !important;
  }

  .Section-title {
    @media screen and (max-width: 545px) {
      display: none;
    }
  }

  // safari browser support
  .FlatMedia-upload-button,
  .FlatVDPPaneHeader-back-button {
    z-index: 1;
  }

  .FlatMedia-upload-button-mobile {
    position: absolute;
    right: 18px;
    top: 9px;
  }

  .VehicleImageView-size-square {
    background-size: cover;
  }
}
