@import "scss/variables";
@import "scss/theme/variables.scss";

.vendor-no-vehicles {
  align-items: center;
  margin-top: 200px;
  font-size: 24px;
}

.vendor-invoice-list {
  display: flex;
  flex-direction: column;
  background-color: #eaecec;
  flex: 1;

  .vendor-invoice-list-content {
    display: flex;
    flex: 1;
  }

  .vendor-invoice-list-container {
    display: flex;
    flex: 1;
    position: relative;
    flex-direction: column;
  }

  .vendor-invoice-list-footer {
    padding: 16px;
    background-color: $recon-background-tertiary;
  }
}
