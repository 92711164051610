@import "../../../../scss/theme/variables.scss";

.MobileVehicleDetails {
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  overflow: hidden;

  &-info {
    height: 60px;
    border: 1px solid $primary;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 8px;

    &-image {
      width: 56px !important;
      height: 44px !important;
      font-size: 44px;
      color: rgb(158, 158, 158);
    }

    &-car-details {
      padding-left: 8px;
      flex: 1;
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: $primary;

      &-year-make {
        font-weight: 600;
      }

      &-stock-number {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: $muted;
      }
    }

    &-icon {
      font-size: 24px;
      color: $primary;
      padding-right: 4px;
    }
  }
}
