@import 'scss/variables';

.AssignmentsCard {
  cursor: pointer;
  border: 0 solid rgba(128, 128, 128, 0.247);
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  padding: 24px;
  margin-bottom: 8px;

  .AssignmentsCard-attention-row {
    margin: 0 -15px -24px -15px;
  }

  &:hover {
    background-color: $recon-item-background-color;
    // outline:2px rgba(128, 128, 128, 0.247) solid;
  }

  .vehicle-attention-image-photo .VehicleImage {
    height: 120px;
    padding: 0;
    margin: 0;
    contain: strict;
  }

  .no-image {
    height: 64px;
    padding: 1px;
  }

  .vehicle-info-container {
    padding-top: 10px;
  }

  .vehicle-attention-image-container {
    min-height: 120px;
    align-content: center;
  }

  .vehicle-attention-description-container {
    .year-make {
      font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .model {
      font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
    }

    padding-bottom: 16px;
  }

  .attention-container {
    padding-bottom: 10px;
  }

  .no-padding {
    padding: 0;
  }
}

.AssignmentsCard-step-name-text {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #263238;
}

.AssignmentsCard-step-progress-text-col {
  text-align: right;
}

.AssignmentsCard-time-name-text {
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.3px;
  color: rgba(38, 50, 56, 0.6);
}

.AssignmentsCard-vin-stock-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: rgba(38, 50, 56, 0.6);
}

.AssignmentsCard-vin-stock-row {
  padding-bottom: 0;
}

.AssignmentsCard-vin-stock-container {
  padding-top: 8px;
}

.AssignmentsCard-mileage-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: rgba(38, 50, 56, 0.6);
}

.AssignmentsCard-mileage-row {
  padding-bottom: 15px;
}

.AssignmentsCard-mileage-container {
  padding-top: 8px;
}

.AssignmentsCard-attention-row {
  background-color: rgba(213, 0, 0, 0.116);
}

.AssignmentsCard-value-metric-container {
  padding: 12px;
  width: 100%;
}

.AssignmentsCard-value-metric-text {
  color: $recon-danger;
}

.AssignmentsCard-icon-container {
  padding: 0 10px 0 0;
  display: flex;
  justify-content: flex-end;

  .AssignmentsCard-icon-bubble {
    border-radius: 100%;
    width: 48px;
    height: 48px;
    background-color: rgba(213, 0, 0, 0.116);
    margin-right: 0;

    .AssignmentsCard-icon {
      color: $recon-danger;
      margin-right: 0;
    }
  }
}

.AssignmentsCard-col {
  padding: 8px !important;
}
