@import '../../../../scss/variables.scss';
@import '../../../../scss/theme/variables.scss';
@import '../FlatTasks.scss';

.Totals {
  display: grid;
  grid-template-areas: '. . . . . . totals-label total-hours labor-price parts-price total-price .';
  grid-template-columns: $grid-template-column-widths;
  background-color: $recon-background-tertiary;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: $spacing-md 0;

  & > div {
    display: flex;
    align-items: center;
  }

  .Totals-hours-label {
    grid-area: total-hours-label;
    display: none;
  }
  .Totals-hours {
    grid-area: total-hours;
  }

  .Totals-labor-label {
    grid-area: labor-label;
    display: none;
  }
  .Totals-labor {
    grid-area: labor-price;
  }

  .Totals-parts-label {
    grid-area: parts-label;
    display: none;
  }
  .Totals-parts {
    grid-area: parts-price;
  }

  .Totals-label {
    grid-area: totals-label;
    justify-content: flex-end;
    margin-right: $spacing-lg;
  }

  .Totals-total {
    grid-area: total-price;
  }

  @media screen and (max-width: $screen-vdp-ipad) {
    grid-template-columns: 0.5fr 2fr max-content repeat(5, 1fr);
    grid-template-areas: '. . . totals-label total-hours labor-price parts-price total-price';
  }

  @media screen and (max-width: $screen-sm-min) {
    grid-template-columns: 0.5fr 2fr max-content repeat(5, 1fr);
    grid-template-areas: '. . . totals-label total-hours labor-price parts-price total-price';
  }
}

.Totals-padding-right {
  padding-right: 17px;
}

.Totals-pinned {
  grid-template-columns: 0.5fr 1.25fr 0.75fr 0.75fr 1fr;
  grid-template-areas:
    '. . . total-hours-label total-hours'
    '. . . labor-label labor-price'
    '. . . parts-label parts-price'
    '. . . totals-label total-price';

  .Totals-hours-label,
  .Totals-labor-label,
  .Totals-parts-label {
    display: inline;
  }
}
