@import "../../../scss/variables";

$hamburger-icon-size: 32px;
$hamburger-status-dot-size: 6px;

$hamburger-padding: $spacing-md;

.HamburgerMenu .PaneHeader {
  border-bottom: 1px solid #eee;
}

.active-rooftop {
  font-weight: 600 !important;
}
