@import "../../../../scss/theme/variables.scss";

.UploadRow {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &-upload-data {
    display: flex;
    flex-direction: column;

    &-filename {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #000;
    }

    &-filesize {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #000;
    }
  }

  &-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
  }

  .CircularProgressbar {
    margin-right: 18px;

    &-path {
      stroke: $success !important;
    }
  }

  .UploadRow-progressbar-container {
    margin-right: 18px;
    width: 32px;
    height: 32px;
  }
}

.UploadRow-progress-icon.MuiSvgIcon-root {
  fill: #dadada !important;

  &:hover {
    fill: #dadada !important;
  }
}

.UploadRow-delete-icon.MuiSvgIcon-root {
  fill: #dadada !important;

  &:hover {
    fill: $danger !important;
  }
}
