@import "../../../../scss/variables.scss";
@import "../../../../scss/theme/variables.scss";

.FlatTasksHeader-desktop {
  height: 48px;
  padding-left: $spacing-slg;
  padding-right: $spacing-lg;
  display: flex;
  flex-direction: row;
  align-items: center;

  .FlatTasksHeader-action-buttons {
    & > .IconButton > .PinIcon {
      margin-right: -6px !important;
    }
  }
}

.FlatTasksHeader-mobile {
  .MuiSvgIcon-root {
    cursor: pointer;
  }

  .FlatTasksHeader-action-buttons {
    background-color: $light;
    height: 52px;
    padding-top: 4px;
    padding-right: 6px;
    justify-content: flex-end;
    align-items: center;
  }
}

// Shared between desktop and mobile
.FlatTasksHeader {
  .FlatTasksHeader-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: $primary;

    .MuiSvgIcon-root {
      margin-right: 16px;
    }
  }

  .FlatTasksHeader-task-buttons {
    display: flex;
    margin-left: $spacing-md;

    & > div {
      margin-left: $spacing-md;
    }
  }

  .FlatTasksHeader-action-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
  }
}
