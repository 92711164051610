@import "../../../../scss/variables.scss";
@import "../../../../scss/mixins.scss";

.ReconInfoSection {
  padding: 0 !important;
  padding-right: 20px !important;
  padding-left: 16px !important;
  padding-bottom: 40px !important;

  &-title-row {
    margin-right: -20px !important;
  }

  &-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: $recon-background-secondary;
    padding: 0 !important;
  }

  &-edit-btn {
    border: 1px solid $recon-alt-text !important;
    border-radius: 5px !important;

    &.btn-secondary {
      &:hover,
      &:focus,
      &.focus,
      &:active,
      &:active:focus,
      &.active {
        border: 1px solid $recon-alt-text !important;
        border-radius: 5px !important;
      }
    }
  }

  .ReconInfoRow {
    box-shadow: inset 0 -1px 0 #e6e7e8;
    padding: 0 !important;
    height: 40px;

    &-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: $recon-alt-text;
      padding: 0 !important;
    }

    &-value {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.3px;
      color: $recon-background-secondary;
      padding: 0 !important;
    }
  }
}
