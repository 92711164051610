.VelocityLocate {
  padding: 0 20px;

  @media screen and (max-width: 414px) {
    padding: 0 10px;
  }

  .VelocityLocate-banner {
    margin-top: 10px;
    height: 60px;
    background-color: #d8dadb;
    background-image: url('../../../Images/map-banner-bg.svg');
  }

  .VelocityLocate-banner-text {
    font-size: 20px;
    letter-spacing: 0.15px;
    font-weight: 400;
    color: #263238;
    margin-top: 3px;
    margin-right: 12px;

    @media screen and (max-width: 1390px) {
      display: none;
    }
  }

  .Velocity-locate-logo {
    width: 185px;
    min-height: 29px;
    background-image: url('../../../Images/velocity-locate-logo.png');
    background-size: cover;

    @media screen and (max-width: 1390px) {
      background-size: contain;
      background-position: center middle;
      background-repeat: no-repeat;
      width: 195px;
    }
  }

  .VelocityLocate-banner-button {
    font-size: 14px;
    
    letter-spacing: 1px;
    color: #fff;
  }
}
