.EditProfileModal {
  padding-left: 8px !important;
  padding-right: 8px !important;

  .MuiSnackbarContent-root {
    flex-wrap: unset !important;
  }

  .select-floating-label {
    font-size: 12px;
    letter-spacing: 0.3px;
    line-height: 24px;
    opacity: 1;
    background-color: white;
    color: rgba(38, 50, 56, 0.5);
    padding: 0 2px 0 2px;
    position: absolute;
    pointer-events: none;
    bottom: 26px;
    left: 32px;
  }
}
