@import './variables.scss';
@import '../variables.scss';

/* --Buttons-- */
.btn {
  border-radius: 5px;
  padding: 8px 15px; // Reducing 1px from design to accomodate 1px border
  font-weight: bold;
  letter-spacing: 1px;
}

.btn-sm {
  padding: 4px 15px;
}

.btn-lg {
  padding: 14px 15px;
  font-size: $font-size-base;
}

.btn:disabled {
  padding: 9px 16px;
  opacity: 1 !important;
  background: rgba(38, 50, 56, 0.12) !important;
  color: rgba(38, 50, 56, 0.12) !important;
  cursor: not-allowed;
  border: 0;
}

.btn-warning {
  color: $light;

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &:active:focus,
  &.active {
    color: $light;
  }
}

.btn-outline-light {
  color: $dark;
}

.dropdown-toggle.btn {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
}

.TextInput-primary {
  input:not(:focus):valid ~ .floating-label {
    color: $primary;
  }
}

/* --Shadows-- */
.shadow-sm {
  box-shadow: 0 4px 8px rgba(38, 50, 56, 0.24) !important;
}

/* --Background Colors-- */
.bg-gray-100 {
  background-color: #f9f9fa;
}

/* --Font-Weights-- */
@each $weight in $font-weights {
  .font-weight-#{$weight} {
    font-weight: $weight !important;
  }
}

@each $color-label, $color  in $theme-colors {
  .icon-button-#{$color-label} {
    color: $color !important;
  }
}

/* --Fonts-- */

.text-lg {
  font-size: 16px;
}

.text-spacing-3 {
  letter-spacing: .3;
}
