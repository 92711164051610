@import "../../../scss/variables.scss";
@import "../../../scss/mixins.scss";

.FlatMedia {
  padding: 0 !important;
  overflow: hidden;

  &-overflow {
    overflow-y: auto;
  }

  &-media-section {
    @media (min-width: 575px) and (max-width: 992px) {
      max-height: 496px !important;
    }
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &-upload-button {
    border: none !important;
    background-color: #fff;
    margin-left: auto;

    &-icon {
      width: 12px;
      height: 14px;
    }
  }

  &-tabs {
    overflow: hidden;

    @media screen and (max-width: 575px) {
      padding-top: 9px !important;
    }
  }

  &-media-content {
    padding-top: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    border-right: 1px solid #eff1f1;
  }

  &-documents-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 32px !important;
    overflow-x: hidden;

    @media (min-width: 575px) and (max-width: 992px) {
      height: calc(100% - 496px) !important;
      overflow-y: scroll;
    }

    @media screen and (max-width: 575px) {
      padding-top: 0 !important;
    }
  }

  &-tab-container {
    border: none !important;
    background-color: #fff;
    margin-left: 16px;
  }

  &-tab-content {
    padding-top: 32px;

    @media screen and (max-width: 575px) {
      padding-top: 24px !important;
    }
  }

  .Gallery {
    padding-left: 16px !important;
    padding-right: 0 !important;
  }

  .gallery-vehicle-image-container {
    height: 108px !important;
    width: 108px !important;
    margin: 8px !important;
    padding: 0 !important;
    background-color: #fff;

    @include xl {
      width: 180px !important;
      height: 180px !important;
    }

    @include lg {
      width: 180px !important;
      height: 180px !important;
    }
  }

  .Gallery-image-view {
    width: 108px;
    height: 108px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include xl {
      width: 180px;
      height: 180px;
    }

    @include lg {
      width: 180px;
      height: 180px;
    }

    video {
      width: 100%;
      height: 100%;
      max-height: 180px;
      max-width: 180px;
      object-fit: cover;
    }
  }

  .Syndication-row {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: $recon-background-secondary;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    justify-content: center;
    box-shadow: inset 0 -1px 0 #eff1f1;
  }

  .Syndication-message.padding {
    padding-bottom: 16px !important;
    padding-right: 24px !important;
  }

  .padding {
    padding: 0 !important;
    padding-left: 24px !important;
  }

  .divider {
    border: none !important;
  }

  .dropdown-menu.show {
    opacity: 1 !important;
    pointer-events: auto !important;
  }
}
