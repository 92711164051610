@import "../../../scss/variables.scss";
@import "../../../scss/theme/variables.scss";

$money-column-width: 85px;
$grid-template-column-widths: 25px 80px 1.5fr 1.75fr 125px
  repeat(2, $money-column-width) 60px repeat(3, $money-column-width) 25px;

.FlatTasks {
  overflow: hidden;
  height: calc(100%);
  display: flex;
  flex-direction: column;

  &-tasks {
    flex: 1;
    overflow: auto;
  }

  &-appraisal-button {
    text-decoration: underline;
  }

  @media screen and (max-width: $screen-sm-min) {
    padding-bottom: 75px; // TODO data disappears on bottom without this
  }

  &-appraisal-button {
    .btn {
      background-color: unset;
    }
  }

  &-footer {
    display: flex;
    flex-direction: row;

    &-create-invoice {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: $recon-background-tertiary;
      padding-left: 8px;
    }

    &-totals {
      flex: 1;
    }
  }
}

.FlatTasks-buttons {
  display: flex;
  margin-bottom: $spacing-md;
  margin-left: $spacing-md;
  margin-right: $spacing-md;

  & > div {
    margin-top: $spacing-md;
    margin-right: $spacing-md;
  }

  &-pinned {
    flex-wrap: wrap;
  }

  @media screen and (max-width: $screen-md-min) {
    flex-wrap: wrap;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  .FlatTasks {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    overflow: visible;

    &-tasks {
      overflow: visible;
    }
  }

  .TaskPhotos-add-photo,
  .FlatTask-sm-label,
  .VehicleDetails-Menu,
  .TasksContainer-action-buttons,
  .VehicleInfoItems-dropdown,
  .TagList-icon,
  .FlatTasks-appraisal-button,
  .FlatTasksHeader,
  .FlatTask-checkbox-col,
  .Task-ActionMenu-drowdown,
  .Header {
    display: none !important;
  }
}
