@import "../../../../scss/theme/variables.scss";

.DateDropdown {
  display: flex;
  flex-direction: row;
  height: 38px;
  cursor: pointer;

  &-divider {
    width: 1px;
    background: rgba(38, 50, 56, 0.12);
  }

  &-icon {
    fill: rgba(38, 50, 56, 0.38) !important;
  }

  &-icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 38px;
  }

  &-filter-label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: $primary;
    text-transform: none !important;
  }

  &-calendar-icon {
    margin-right: 5px;
  }

  &-main-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 180px;
  }
}

.DateDropdown-container {
  margin-right: 28px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 5px;

  .dropdown-toggle {
    padding: 0 !important;
  }

  .dropdown-toggle::after {
    content: none;
  }
}
