@import 'scss/variables.scss';

.StepButtons {
  white-space: nowrap;
  overflow: hidden;

  .VehicleDetails-section & .MuiSnackbar-root {
    position: absolute;
  }

  .VehicleCard & .MuiSnackbar-root {
    position: relative;
  }

  .StepButtons-pause-col {
    padding-right: 5px;
  }

  .StepButtons-continue-col {
    padding-left: 5px;
  }

  .step-button-base {
    width: 100% !important;
    height: 32px;
    justify-content: center;
    line-height: initial !important;
    overflow: hidden;
    text-overflow: ellipsis !important;
    border-radius: 3px;
    display: flex !important;
    align-items: center;

    span + span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .step-button-text {
    text-transform: capitalize !important;
  }

  .continue-button {
    letter-spacing: 1px;
  }

  .continue-button,
  .complete-workflow-button {
    width: 153px !important;
  }

  .selected-button {
    background-color: $recon-primary !important;
    color: $recon-white !important;
    border: none !important;
  }

  .complete-button-base {
    background-color: $recon-complete-button !important;
    color: $recon-white !important;
    border: none !important;
  }

  .pause-button {
    background-color: $recon-pause-button !important;
    color: $recon-white !important;
    border: none !important;
  }

  .pause-button,
  .complete-work-button {
    width: 60.5px !important;
  }

  .simple-state-complete-button {
    width: 153px !important;
  }

  .unselected-button {
    background-color: $recon-white !important;
    color: $recon-secondary !important;
  }

  .button-container {
    display: flex;
    justify-content: space-between;

    button {
      border-radius: 2px;
    }
  }

  .pause-button:disabled,
  .complete-button-base:disabled,
  .continue-button:disabled {
    color: $recon-background-secondary !important;
    background-color: $recon-disabled !important;
    border: 0;
  }
}

.StepButtons-small {
  .complete-button-base,
  .continue-button,
  .pause-button {
    min-height: 32px;
    min-width: 32px;
  }
}
