.EditNotesModal {
  .EditNotesModal-input-container {
    min-height: 200px;
    border-width: 2px;
  }

  .DraftEditor-root {
    max-height: 80px;
    overflow-y: scroll;
  }
}
