@import "../../../../scss/variables.scss";

.StepProgressSection {
  padding: 0 !important;

  .step-name-table {
    max-width: 200px;
  }

  &-table {
    white-space: nowrap;

    &-header-row {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.25px;
      color: $recon-background-secondary;

      th {
        border: none !important;
        padding-left: 0 !important;
        padding-right: 24px !important;
      }
    }

    &-data-row {
      .StepProgressSection-step-title {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.25px;
        color: $recon-alt-text;

        &-active {
          color: $recon-background-secondary;
        }
      }

      td {
        padding: 0 !important;
        padding-right: 24px !important;
        vertical-align: middle !important;
        font-size: 16px;
        letter-spacing: 0.3px;
        color: $recon-background-secondary;
        max-width: 200px;
        min-width: 92px;
      }

      td:first-child {
        height: 40px !important;
        max-height: 40px !important;
        min-height: 40px !important;
      }

      .StepProgressSection-progress-bar-container {
        .StepProgressSection-progress-bar {
          height: 4px !important;
          width: 76px;
          min-width: 76px;
          max-width: 76px;
        }
      }
    }
  }

  &-title-container {
    padding-bottom: $spacing-lg;

    .StepProgressSection-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.3px;
      color: $recon-background-secondary;
      text-transform: capitalize;
    }
  }

  .padding-0 {
    padding-left: 0 !important;
  }

  .padding-1 {
    padding-left: $spacing-lg !important;
  }

  .padding-2 {
    padding-left: $spacing-xl !important;
  }

  .active {
    color: #1ab245;
  }

  .complete {
    color: #1ab245;
  }

  .alarm-icon {
    margin-right: 10px;
  }
}
