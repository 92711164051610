.VelocityEngageMediaTab {
  padding: 24px;
  overflow-y: auto;

  &-no-media {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: rgba(38, 50, 56, 0.38);
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &-alert {
    top: 80px !important;
  }
}

.Featured-icon {
  color: #ffab00;
  margin-right: 12px;
  font-size: 20px !important;
}

.Divider {
  border: 1px solid rgba(38, 50, 56, 0.12);
  margin-bottom: 24px;

  @media screen and (max-width: 575px) {
    margin-top: 12px;
  }
}
