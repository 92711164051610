@import "../../../../scss/variables.scss";
@import "../../../../scss/theme/variables.scss";
@import "../FlatTasks.scss";

.FlatTask {
  display: grid;
  grid-template-areas: "checkbox status task description assignee photos rate hours labor parts total delete";
  grid-template-columns: $grid-template-column-widths;
  background-color: $recon-white;

  & > div {
    display: flex;
    align-items: center;
  }

  input {
    width: 100%;
    border: none;

    &:focus {
      outline: none;
      border: 1px solid $info;
    }
  }

  &:nth-child(even) {
    background: $recon-background-tertiary;
  }

  .FlatTask-checkbox-col {
    grid-area: checkbox;
    justify-content: center;

    .checkbox-container {
      margin: 0;
      padding: 0;
      max-width: 25px;

      .p-2 {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }

  .FlatTask-status-col {
    grid-area: status;
    text-transform: uppercase;
  }
  .FlatTask-status-Estimated {
    color: $info;
  }
  .FlatTask-status-Requested {
    color: $warning;
  }
  .FlatTask-status-Approved,
  .FlatTask-status-Completed {
    color: $success;
  }
  .FlatTask-status-Denied {
    color: $muted;
  }
  .FlatTask-status-Invoiced {
    color: #44c7ff;
  }
  .FlatTask-status-Paid {
    color: #a0d66a;
  }

  .FlatTask-task-col {
    grid-area: task;
  }

  .FlatTask-description-col {
    grid-area: description;
    background-color: unset;
    border: none;
    padding: 0;
    width: 100%;
    word-break: break-word;
  }

  .FlatTask-assignee-col {
    grid-area: assignee;
  }

  .FlatTask-photos-col {
    grid-area: photos;
  }

  .FlatTask-hours-col {
    grid-area: hours;
  }

  .FlatTask-rate-col {
    grid-area: rate;
  }

  .FlatTask-labor-price-col {
    grid-area: labor;
  }

  .FlatTask-parts-price-col {
    grid-area: parts;
  }

  .FlatTask-total-price-col {
    grid-area: total;
    cursor: default;
  }

  .FlatTask-delete-col {
    grid-area: delete;
    justify-content: center;
  }

  .FlatTask-custom-type-input {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    input {
      width: 100%;
      border: none;

      &:focus {
        outline: none;
        border: 1px solid $info;
      }
    }
  }

  .MuiSvgIcon-root {
    fill: $muted;
  }

  .FlatTask-caret {
    fill: $recon-dropdown-arrow-color;
  }

  .FlatTask-option {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-icon {
      fill: $primary;
    }
  }

  textarea {
    resize: none;

    &:focus {
      border: 1px solid $info;
      outline: none;
    }
  }

  .Loading-centered {
    display: flex;
    align-items: center;
    color: rgba(38, 50, 56, 0.38);
  }

  @media screen and (min-width: $screen-vdp-ipad) {
    .FlatTask-sm-label {
      display: none;
    }
  }

  @media screen and (max-width: $screen-vdp-ipad) {
    grid-template-columns: 0.5fr 2fr max-content repeat(5, 1fr);
    grid-template-rows: repeat(7, minmax(32px, auto));
    grid-template-areas:
      "checkbox status-label status status status status status status"
      ". task-label task task task task task task"
      ". description-label description description description description description description"
      ". assignee-label assignee assignee assignee assignee assignee assignee"
      ". photos-label photos photos photos photos photos photos"
      ". cost-label . rate hours labor parts total"
      ". . . . . . . delete";

    .FlatTask-labor-label,
    .FlatTask-parts-label,
    .FlatTask-total-label {
      display: none;
    }

    .FlatTask-sm-label {
      font-weight: bold;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $recon-background-secondary;
    }

    .FlatTask-status-label {
      grid-area: status-label;
    }

    .FlatTask-task-label {
      grid-area: task-label;
    }

    .FlatTask-description-label {
      grid-area: description-label;
    }

    .FlatTask-assignee-label {
      grid-area: assignee-label;
    }

    .FlatTask-photos-label {
      grid-area: photos-label;
    }

    .FlatTask-costs-label {
      grid-area: cost-label;
    }
  }
}

.FlatTask-Completed-strike-through,
.FlatTask-Completed-strike-through * {
  text-decoration: line-through;
  color: $success !important;
}

.FlatTask-Denied-strike-through,
.FlatTask-Denied-strike-through * {
  text-decoration: line-through;
  color: $muted !important;
}

.FlatTask-pinned {
  grid-template-columns: 0.5fr 1.25fr 0.75fr 0.75fr 1fr;
  grid-template-rows: repeat(9, minmax(32px, auto));
  grid-template-areas:
    "checkbox status-label status status status"
    ". task-label task task task"
    ". description-label description description description"
    ". assignee-label assignee assignee assignee"
    ". photos-label photos photos photos"
    ". labor-label rate hours labor"
    ". parts-label . . parts"
    ". total-label . . total"
    ". . . . delete";

  .FlatTask-sm-label {
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $recon-background-secondary;
    display: flex;
    align-items: center;
  }

  .FlatTask-task-label {
    grid-area: task-label;
  }

  .FlatTask-description-label {
    grid-area: description-label;
  }

  .FlatTask-assignee-label {
    grid-area: assignee-label;
  }

  .FlatTask-photos-label {
    grid-area: photos-label;
  }

  .FlatTask-labor-label {
    grid-area: labor-label;
  }

  .FlatTask-parts-label {
    grid-area: parts-label;
  }

  .FlatTask-total-label {
    grid-area: total-label;
  }

  .FlatTask-costs-label {
    display: none;
  }
}
