@import "../../../../scss/variables";
@import "../../../../scss/mixins.scss";
@import "../../../../scss/theme/variables.scss";

.gallery-vehicle-image-container {
  padding: 10px;
  height: 200px;
  width: 200px;
}

.mobile-viewport .gallery-vehicle-image-container {
  height: 140px;
  width: 140px;
  justify-self: center;
}

.mobile-viewport .adjustable-grid {
  display: flex;
  width: 100vw;
  padding-left: $spacing-md;
  padding-right: $spacing-md;
  grid-row-gap: $spacing-sm;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-template-rows: repeat(auto-fit, 150px);
  grid-auto-flow: row;
}

.Gallery-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 24px;
}

.Gallery {
  max-height: 5000px !important;

  @include sm {
    max-height: 496px !important;
  }

  &-hidden-image {
    display: none !important;
  }
}

.white-background {
  background-color: #fff;
}

.Gallery-image-view-video {
  position: relative;
  display: inline-block;
  white-space: nowrap;
}
