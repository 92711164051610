@import "../../../../scss/variables";

.monthly-review-row {
  height: 86px;
}

.current-value-col {
  border-style: solid;
  border-width: 0 1px 0 1px;
  border-color: rgba(128, 128, 128, 0.247);
}

.value {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 2px;
  text-align: center;
}

.difference-value-good {
  color: $recon-ok;
}

.difference-value-bad {
  color: $recon-danger;
}

.metric {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: rgba(38, 50, 56, 0.6);
  margin-top: 2px;
  text-transform: uppercase;
}
