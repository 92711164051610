@import "../../../../../scss/theme/variables.scss";

.MenuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 14px;
  padding-right: 16px;
  padding-left: 20px;
  height: 42px;

  &-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &-icon {
      width: 28px;
    }

    &-label {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.3px;
      color: $primary;
      margin-left: 18px;
    }
  }

  &-chip {
    background-color: $danger;
    height: 24px;
    border-radius: 12px;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    &-text {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      display: flex;
      align-items: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $light;
    }
  }
}
